import React from "react";

import { Whatsapp } from "./Icons";
const Footer = () => {
  return (
    <div className="footer_section">
      <div className="footer_section_wrapper">
        <div className="container">
          <div className="footer_logo">
            <h1 className="">Commercials</h1>
          </div>
          <div className="footer_main">
            <div className="contact_address">
              <h4 className="text-bold">Mobile</h4>
              <a href="tel:+918754088080" className="">
                +91 8754 08 8080
              </a>
            </div>
            <div className="contact_address">
              <h4 className="text-bold">Mail</h4>
              <a href="mailto:info@thecommericals.in" className="">
                info@thecommericals.in
              </a>
            </div>
            <div className="contact_address">
              <h4 className="text-bold">Address</h4>
              <p className="paragraph">
                8, Mahatma Gandhi Nagar, <br /> Singanallur, Coimbatore -
                641005.
              </p>
            </div>
          </div>
          <div className="footer_bottom">
            <div className="footer_credits">
              <p className="">&copy; Commercials. All RIghts Reserved.</p>
            </div>
            <div className="footer_credits whatsapp">
              <a href="https://wa.me/+918754088080">
                <Whatsapp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
