import React, { useRef, useEffect } from "react";
import AboutIntro from "../images/about_intro.jpg";
import cLogo from "../images/c.png";
import { gsap, SlowMo } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
const About = () => {
  const floatImgRef = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (floatImgRef.current == null) return;
    const tween = gsap.from(floatImgRef.current, {
      rotate: "90deg",
      y: "40",
      duration: 1,
      ease: SlowMo,
      scrollTrigger: {
        trigger: document.querySelector("#about"),
        scrub: true,
        // markers: true,
      },
    });

    return () => {
      tween.kill();
    };
  }, []);

  return (
    <section id="about" className="about_section section_vh">
      <div className="container">
        <div className="about_intro">
          <div className="about_intro_left">
            <div className="about_intro_left_wrapper">
              <div className="about_intro_left_img">
                <img
                  className="ratio ratio-3x4"
                  src={AboutIntro}
                  alt="The Commercials"
                  title="The Commercials"
                />
              </div>
              <img
                src={cLogo}
                ref={floatImgRef}
                className="about_intro_left_img--float"
                alt="The Commercials"
                title="The Commercials"
              />
            </div>
          </div>
          <div className="about_intro_right">
            <div className="about_intro_right_content">
              {/* <p className="subtitle">We Are</p> */}
              <h2 className="display-3 mt-2">
                A Dynamic Branding Agency in Coimbatore
              </h2>
              <p className="paragraph mt-4">
                Our team of talented and creative professionals bring a wealth
                of experience and skills to the table, ensuring that our clients
                receive the highest quality services. From brand strategy and
                identity development to website design and social media
                marketing, we offer a comprehensive suite of services that cater
                to the diverse needs of our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
