import React from "react";
import { CommercialsCircle } from "../components/Icons";
const Home = () => {
  return (
    <>
      <section className="hero_section" id="home">
        <div className="container">
          <div className="hero_section_wrapper">
            <h1 className="display-3">
              Unlock the Power of Your Brand
              {/* <br /> With Commercials */}
            </h1>
            <p className="mt-4 paragraph">
              Elevate Your Business with Expert Branding <br /> and Innovative
              Marketing Strategies.
            </p>
            <div className="mt-5">
              <a href="#contact" className="primary_button">
                Let's Grow Together
              </a>
            </div>
          </div>
          <div className="commercials_circle">
            <CommercialsCircle />
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
