import React from "react";
// import "./Content.scss";
import Home from "./Home";
import Navbar from "./../components/Navbar";
import About from "./About";
import Services from "./Services";
import Contact from "./Contact";
import Footer from "../components/Footer";

function Layout() {
  // const navRef = useRef([]);

  //   const location = useLocation();
  //   const pathName = useLocation();

  //   useEffect(() => {
  //     window.scrollTo({ top: 0, behavior: "instant" });
  //   }, [pathName]);

  return (
    <>
      <Navbar />
      <Home />
      <About />
      <Services />
      <Contact />
      <Footer />
    </>
  );
}

export default Layout;
