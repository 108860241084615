import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { init } from "emailjs-com";

init("n37luSdB9CmSzWKBf");
// import ContactIntro from "../images/contact_bg.jpg";
const Contact = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showError, setShowError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const SubmitForm = (data, r) => {
    r.preventDefault();
    const templateId = "template_wwi1ssc";
    const serviceID = "service_49b5gdj";
    console.log(data);
    sendFeedback(serviceID, templateId, {
      c_name: data.name,
      c_contact: data.phone,
      c_email: data.email,
      c_message: data.message,
    });
    r.target.reset();
  };
  const sendFeedback = (serviceID, templateId, variables) => {
    emailjs
      .send(serviceID, templateId, variables)
      .then((res) => {
        setTimeout(() => {
          setShowSnackbar(true);
        }, 1000);
      })
      .catch((err) => {
        setShowError(true);
        setTimeout(() => {
          setShowError(false);
        }, 5000);
      });
  };
  return (
    <section id="contact" className="contact_section section_padding">
      <div className="container">
        <div className="contact">
          <div className="contact_left">
            <div className="contact_left_wrapper">
              {/* <p className="subtitle">One Call Away</p> */}
              <h2 className="display-4">
                Connect With Us Today To Discuss Your Business Needs
              </h2>
            </div>
          </div>
          <div className="contact_form_wrapper">
            <form
              id="contact_form"
              className="contact_form mt-4"
              method="post"
              onSubmit={handleSubmit(SubmitForm)}
            >
              <div className="form_input_wrapper mb-3">
                <input
                  className={`form-control input_field ${
                    errors.name ? "is-invalid" : ""
                  }`}
                  type="text"
                  name="name"
                  id="name_field"
                  placeholder="Name"
                  {...register("name", { required: true, maxLength: 50 })}
                />
                {errors.name?.type === "maxLength" && (
                  <div className="invalid-feedback">
                    Max Length not exceeding 50 letters
                  </div>
                )}
                {errors.name?.type === "required" && (
                  <div className="invalid-feedback">Name required</div>
                )}
              </div>
              <div className="form_input_wrapper mb-3">
                <input
                  className={`form-control input_field ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  type="email"
                  name="email"
                  id="email_field"
                  placeholder="Email"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    },
                  })}
                />
                {errors.email?.type === "pattern" && (
                  <div className="invalid-feedback">
                    Enter valid Email address
                  </div>
                )}
                {errors.email?.type === "required" && (
                  <div className="invalid-feedback">Email required</div>
                )}
              </div>
              <div className="form_input_wrapper mb-3">
                <input
                  className={`form-control input_field ${
                    errors.mobile ? "is-invalid" : ""
                  }`}
                  type="text"
                  name="mobile"
                  id="mobile_no"
                  placeholder="Contact No"
                  {...register("mobile", { required: true })}
                />
                {errors.mobile?.type === "required" && (
                  <div className="invalid-feedback">
                    Contact number required
                  </div>
                )}
              </div>
              <div className="form_input_wrapper mb-3">
                <textarea
                  className={`form-control input_field`}
                  rows="5"
                  name="message"
                  id="message_field"
                  placeholder="Message"
                  required=""
                />
              </div>
              <div className="form_input_wrapper mt-5">
                <input
                  type="submit"
                  value="submit"
                  className="primary_button primary_button--accent"
                />
              </div>
              <div className="text-primary py-3">
                <div className={showSnackbar ? "snack_bar show" : "snack_bar"}>
                  Thank you! We will contact you shortly.
                </div>
              </div>
              <div className="text-danger">
                {showError ? (
                  <h5>
                    <b>
                      Something went wrong,
                      <br /> Please try after sometime
                    </b>
                  </h5>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
