import React, { useState, useEffect } from "react";
import { gsap, Power4 } from "gsap/dist/gsap";

// import { FaHome, FaPhoneAlt } from "react-icons/fa";
const navRoutes = [
  { path: "#home", name: "Home" },
  { path: "#about", name: "About" },
  { path: "#services", name: "Services" },
  { path: "#contact", name: "Contact" },
];
function Navbar() {
  const [tl, setTl] = useState(null);
  // const [isActive, setIsActive] = useState(null);
  // const toggleActive = setIsActive(!isActive);
  useEffect(() => {
    setTl(
      gsap
        .timeline({
          paused: true,
        })

        .set(".navbar_logo", {
          display: "none",
          autoAlpha: 0,
        })
        .set(".navbar_menu", {
          autoAlpha: 0,
          display: "none",
        })
        .to(".menu_bar", {
          display: "none",
          autoAlpha: 0,
          delay: -0.5,
          ease: Power4.easeInOut,
        })
        .to(".navigation_bar", {
          minHeight: "100vh",
          background: "#1b1b1b",
          duration: 0.1,
          delay: 0.1,
          ease: Power4.easeInOut,
        })
        .to(".navbar_menu", {
          display: "flex",
          // height: "100vh",
          autoAlpha: 1,
          x: 0,
          stagger: 0.05,
          duration: 0.2,
          ease: Power4.easeInOut,
        })
        .to(".menu_bar_close", {
          display: "flex",
          autoAlpha: 1,
          duration: 0.2,
          ease: Power4.easeInOut,
        })
    );
  }, []);
  const handleCloseMenu = () => {
    if (document.body.classList.contains("menu_open")) {
      document.body.classList.remove("menu_open");
      tl.reverse();
      console.log("111");
    }
  };
  return (
    <>
      <div className="navigation_bar">
        <div className="container">
          <div className="navigation_bar_wrapper">
            <div className="navbar_logo">
              <a href="#home">Commercials</a>
            </div>
            <div className="navbar_menu">
              {navRoutes.map((item, index) => (
                <div className="navbar_menu_item" key={index}>
                  <a
                    // onClick={toggleActive}
                    href={item.path}
                    onClick={handleCloseMenu}
                    // className={
                    //   isActive ? "navbar_menu_link active" : "navbar_menu_link"
                    // }
                  >
                    {item.name}
                  </a>
                </div>
              ))}
            </div>
            <div
              className="menu_bar"
              onClick={() => {
                document.body.classList.add("menu_open");
                tl?.play();
                console.log("111");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <div
              className="menu_bar_close"
              onClick={() => {
                document.body.classList.remove("menu_open");
                tl.reverse();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
