import React from "react";
import {
  AdProduction,
  Branding,
  DigitalMarketing,
  Print,
  ProductAnime,
  WebDesign,
} from "../components/Icons";
const Services = () => {
  // const [active, setActive] = React.useState(0);

  return (
    <section id="services" className="service_section section_padding">
      <div className="container">
        <div className="service_section_title_wrapper m-auto text-center mb-5">
          {/* <p className="subtitle">We Create</p> */}
          <h2 className="display-3 mt-2">
            Discover Our Range of Professional Offerings
          </h2>
        </div>
        <div className="service_items_wrapper">
          <div className="service_items">
            {ServiceData.map((item, index) => (
              <div
                // className={`service_item ${
                //   active === item ? "selected" : "not_selected"
                // }`}
                className="service_item"
                key={index}
                // onMouseOver={setActive(item)}
                // onMouseLeave={() => setActive(0)}
                // ref={(el) => (hoverRef.current[index] = el)}
              >
                <div className="service_item_wrapper">
                  <div className="service_item_icon">{item.serviceIcon}</div>
                  <h3 className="service_item_title">{item.serviceTitle}</h3>
                  <p className="paragraph">{item.serviceDesc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;

export const ServiceData = [
  {
    id: 1,
    serviceIcon: <Branding />,
    serviceTitle: "Branding",
    serviceDesc:
      "We believe that branding is not just about creating a logo or a tagline. It is about creating a comprehensive brand identity that reflects your business values, culture, and personality. Our team of experts takes a collaborative approach,  to create a brand that is authentic, memorable, and resonates with your target audience.",
  },
  {
    id: 2,
    serviceIcon: <DigitalMarketing />,
    serviceTitle: "Digital Marketing",
    serviceDesc:
      "We take pride in our ability to deliver measurable results and provide our clients with a clear return on investment. We offer a comprehensive range of services, including search engine optimization (SEO), social media marketing, pay-per-click (PPC) advertising, content marketing, email marketing, and more.",
  },
  {
    id: 3,
    serviceIcon: <WebDesign />,
    serviceTitle: "Web Design & Development",
    serviceDesc:
      "We understand that a website is more than just a digital brochure. It is a powerful marketing tool that can help businesses establish their brand, build credibility, and drive conversions. That's why we focus on creating websites that are not only beautiful but also functional and optimized for search engines. ",
  },
  {
    id: 4,
    serviceIcon: <AdProduction />,
    serviceTitle: "Ad Production",
    serviceDesc:
      "We believe that an effective ad is one that not only captures the attention of the viewer but also delivers a clear and compelling message. That's why we focus on creating ads that are not only visually appealing but also informative and persuasive.",
  },
  {
    id: 5,
    serviceIcon: <ProductAnime />,
    serviceTitle: "Product Animations",
    serviceDesc:
      "We believe that an effective product animation is one that not only showcases the product's features and benefits but also tells a story and engages the viewer on an emotional level. That's why we focus on creating animations that are not only visually stunning but also informative, entertaining, and memorable.",
  },
  {
    id: 6,
    serviceIcon: <Print />,
    serviceTitle: "Print & Publication",
    serviceDesc:
      "We understand that print media remains an essential part of any marketing strategy. a well-designed and professionally printed publication can make a lasting impression on the reader and help businesses stand out in a crowded marketplace. That's why we focus on delivering high-quality printing and publication services.",
  },
];
